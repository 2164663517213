td span.badge {
  cursor: pointer;
}

input[type="radio"].styled {
  width: 20px;
  height: 20px;
}

input[type="radio"].OPEN {
  accent-color: #ff0000;
}
input[type="radio"].CREATED {
  accent-color: #ff0000;
}

/* input[type="radio"].RESOLVED {
  accent-color: #00ff00;
} */
input[type="radio"].Closed {
  accent-color: #00ff00;
}

input[type="radio"].PROCESSING {
  accent-color: #ffcc00;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  /* background: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  /* outline: 1px solid slategrey; */
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 387px;
  overflow-y: scroll;
}

/* On Issu Css */

td span.badge {
  cursor: pointer;
}

/* input[type=radio] {
  border: 0px;
  width: 100%;
  height: 2em;
} */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages.chat-box {
  height: 335px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="media"] {
    width: 100%!important;
    margin-top: 10px;
  }
}